
.container {
	display: flex;
	margin: 10px;
}

.modal {
	display: flex;
	margin: 10px;
	flex-direction: column;
}

.menu {
	margin-right: 20px;
}

.content {
	display: flex;
	flex-direction: column;
	flex: 1;
}

h1 {
}
